.philosophie-page-container {
  background-color: #ffffff;
  padding: 2em 1em;
}

.philosophie-CASCO {
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 40px;
  justify-content: center;
  vertical-align: top;
  flex-wrap: wrap;
  max-width: 80%;
  column-gap: 60px;
  row-gap: 30px;
  text-align: justify;
}

.philosophie-CASCO p {
  font-size: 18px;
  text-align: start;
  font-family: Poppins;
  font-weight: 300;
}

.philosophie-CASCO-title {
  font-size: 40px;
  text-align: center;
  padding: 1em;
  font-family: Poppins;
  font-weight: 600;
  color: #662383;
}
.philosophie-CASCO-title h1 {
  font-size: 25px;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  padding-top: 10px;
  color: #000;
}
.philosophie-img-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 2em;
}
.philosophie-CASCO-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50%;
}
.philo-img {
	max-height: 100%;
	max-width: 100%;
	margin: 10px;
}
.philosophie-CASCO-description {
  max-width: 50%;
  font-family: Poppins;
  padding: 0px 20px 0px 20px;
}
.philosophie-CASCO-span {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  margin: auto;
  font-size: 16px;
  text-align: center;
  font-family: Poppins;
  background-color: #fff7eb;
  border-radius: 12px;
  padding: 5px 2em;
  overflow-wrap: break-word;
}
@media (max-width: 600px) {
  .philosophie-CASCO {
    max-width: 100%;
  }
  .philosophie-CASCO-description p {
    font-size: 14px;
  }
  .philosophie-CASCO-span {
    margin-top: 2em;
  }
  .philosophie-CASCO-span p {
    font-size: 15px;
  }
  .philosophie-header-description p {
    text-align: start;
  }
}

@media (max-width: 800px) {
  .philosophie-CASCO-image {
    display: none;
  }
  .philosophie-img-text {
    justify-content: center;
    gap: 0;
    max-width: 100% !important;
  }
  .philosophie-CASCO-description {
    max-width: 100%!important;
    padding: 0;
  }
  .philosophie-CASCO-split2 {
    max-width: 100% !important;
    padding: 10px !important;
  }
}


.philosophie-CASCO-span span {
  font-weight: bold;
}
.philosophie-CASCO-description h1 {
  font-family: Poppins;
  font-weight: 450;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 50px;
}
.philosophie-CASCO-description p {
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  color: #161616;
}

.philosophie-CASCO-description h1 span,
.philosophie-CASCO-description p span {
  color: #000000;
  font-weight: bold;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #000000;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.philosophie-CASCO-split2 {
  max-width: 400px;
  padding: 70px 0px 30px 0px;
  font-size: 16px;
}
.philosophie-CASCO-split2 p {
  font-family: Poppins;
  font-weight: 300;
  line-height: 30px;
  color: #161616;
}
.philosophie-CASCO-split2 span {
  color: #662383;
  font-weight: bold;
}

@media (max-width: 520px) {
  .philosophie-CASCO {
    margin: 0px 0px 10px;
    padding: 10px 20px;
    row-gap: 0px;
  }
  .philosophie-CASCO p {
    font-size: 14px;
  }
  .philosophie-CASCO-title {
    font-size: 20px;
    line-height: 25px;
  }
  .philosophie-CASCO-title h1 {
    font-size: 18px;
    line-height: 25px;
    margin: 0px 20px 0px;
  }
  .philosophie-CASCO-description p {
    font-size: 14px;
  }
  .philosophie-CASCO-split2 {
    font-size: 14px;
    padding: 0px 0px 0px;
  }
}

