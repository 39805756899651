.specialite-text {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
} 
.specialite-text p {
    width: 1035px;
    font-size: 15px;
}    
.specialite-text h2 {
    font-size: 20px;;
}

.table-logopedie {
    width: 100%;
    height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  caption {
    position: relative;
    font-family: 'Poppins', cursive;
    padding: 20px;
    font-style: italic;
    font-size: 20px;;
    color: #666;
    text-align: center;
    letter-spacing: 1px;
  }
  table {
    border: 2px solid forestgreen;
    width: 800px;
    height: 300px;
  }

  tr {
    text-align: center;
  }
    
  th {
    border-bottom: 1px solid black;
    font-size: 15px;;

  }
    
  td {
    text-align: center;
    font-size: 12px;;
  }

.table-header {
    font-size: 20px;; 
}