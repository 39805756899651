.product-main-container {
  background-color: #ffffff;
}
.product-main {
  background-color: #ffffff;
  margin: auto;
  padding: 60px 20px 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.product-main h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 32px;
  line-height: 37px;
  text-align: center;
  color: #022282;
  margin-bottom: 50px;
}
.product-main h1 span {
  color: #ff511a;
}
.title-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 30px;
  margin-bottom: 63px;
}
.main-description {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #161616;
  margin-bottom: 30px;
  max-width: 995px;
}
.image-description-1 {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #161616;
  max-width: 466px;
}

.image-description-2 {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  max-width: 365px;
}
.image-description-3 {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  max-width: 467px;
}
.image-description-4 {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #161616;
  max-width: 300px;
  text-align: center;
  margin-top: 23px;
}

/* Risk Analysis Component */
.risk-analysis-first-item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 85px;
  row-gap: 20px;
  margin-bottom: 90px;
  flex-wrap: wrap-reverse;
}
.risk-analysis-second-item {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  column-gap: 137px;
  row-gap: 20px;
}
.second-item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 38px;
}
/* Prediction Health Factors Component */
.prediction-health-factors-first-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap-reverse;
  column-gap: 80px;
  row-gap: 20px;
  margin-bottom: 80px;
}
.prediction-health-factors-first-image {
  width: 440px;
}
.prediction-health-factors-second-item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 85px;
  row-gap: 20px;
  flex-wrap: wrap;
}
/* Automated Coaching Component */
.automated-coaching-first-item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 80px;
  row-gap: 20px;
  margin-bottom: 70px;
  flex-wrap: wrap-reverse;
}
.automated-coaching-second-item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 125px;
  row-gap: 20px;
  margin-bottom: 85px;
  flex-wrap: wrap;
  padding-left: 64px;
}
.responsive-description-title {
  display: none;
}
.description-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  color: #161616;
  margin-bottom: 26px;
}
.automated-coaching-third-item {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 80px;
  row-gap: 20px;
  margin-bottom: 80px;
  flex-wrap: wrap-reverse;
}
.automated-coaching-fourth-item {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 42px;
  row-gap: 30px;
}
.fourth-item-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1014px) {
  .automated-coaching-second-item {
    padding-left: 27px;
  }
  .description-title {
    display: none;
  }
  .responsive-description-title {
    display: block;
    width: 467px;
  }
  .responsive-description-title h6 {
    font-family: Poppins;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #161616;
    margin-bottom: 10px;
  }
}
@media (max-width: 875px) {
  .title-wrapper svg {
    display: none;
  }
}

@media (max-width: 768px) {
  .product-main {
    padding: 30px 20px;
  }
}
@media (max-width: 520px) {
  .product-main {
    padding: 30px 20px;
  }
  .product-main h1 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  .risk-analysis-first-item {
    margin-bottom: 30px;
  }
  .image-description-1 {
    font-size: 16px;
    line-height: 24px;
    max-width: 350px;
  }
  .second-item-card {
    row-gap: 20px;
  }
  .prediction-health-factors-first-item {
    margin: 20px 0px 30px;
  }
  .main-description {
    font-size: 16px;
    line-height: 24px;
    max-width: 350px;
  }
  .image-description-3 {
    max-width: 350px;
  }
  .title-wrapper {
    margin-bottom: 30px;
  }
  .title-wrapper svg {
    display: none;
  }
  .automated-coaching-first-item {
    margin-bottom: 30px;
  }
  .automated-coaching-second-item {
    margin-bottom: 30px;
    padding-left: 0px;
  }
  .responsive-description-title {
    display: flex;
    justify-content: center;
    width: unset;
  }
  .responsive-description-title h6 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 20px;
    text-align: center;
  }
  .automated-coaching-third-item {
    margin-bottom: 30px;
  }
  .image-description-4 {
    font-size: 16px;
    line-height: 24px;
    max-width: 350px;
    margin-top: 20px;
  }
  .risk-analysis-first-image,
  .prediction-health-factors-first-image,
  .prediction-health-factors-second-image,
  .automated-coaching-first-image,
  .automated-coaching-third-image {
    width: 335px;
    height: 254px;
  }
}
