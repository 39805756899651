.chien-container {
  margin: 1em 0em;
}
.chien-header-container {
  background: linear-gradient(0.25turn, #fff7eb, #ffffff, #fff7eb);
  padding: 2em 4em;
  margin-bottom: 40px;
}
.chien-header-container h1 {
  font-family: 'Poppins';
  text-align: center;
  font-size: 30px;
  color: #662383;
  margin: 1em 0 2em;
}

.chien-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  column-gap: 2em;
  row-gap: 2em;
}
.chien-photo1 {
	max-height: 450px;
}

.chien-header-description {
  max-width: 50%;
}
.chien-header-description h1 {
  font-family: Poppins;
  font-weight: 450;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 50px;
}
.chien-header-description p {
  font-family: Poppins;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #161616;
  text-align: justify;
}
.chien-header-description h1 span,
.chien-header-description p span {
  color: #94c11f;
  font-weight: 550;
}
.chien-header-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.chien-CASCO-container {
  /*background: linear-gradient(
    to left,
      #f8ecfd,
      #ffffff
    );
    */
   background-color: #ffffff;
}



/*Bloc 2*/

.chien-container2 {
  margin: 0 4em;
}

.chien-container2 h1 {
  color: #662383;
  font-family: 'Poppins';
  text-align: center;
  font-size: 30px;
}

.chien-container2 p {
  font-family: 'Poppins';
  text-align: center;
  margin: 1em 0 2em;
  font-size: 16px;
}

.chien-cards-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 10px;
}
.chien-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  max-width: 20%;
}
.chien-card-text {
  max-width: 150px;
}
.chien-card1 {
  border-radius: 15px;
  height: calc(100vw /8);
  width: calc(100vw /8);
  max-width: 150px;
  max-height: 150px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}
.chien-card2 {
  border-radius: 15px;
  height: calc(100vw /8);
  width: calc(100vw /8);
  max-width: 150px;
  max-height: 150px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}
.chien-card3 {
  border-radius: 15px;
  height: calc(100vw /8);
  width: calc(100vw /8);
  max-width: 150px;
  max-height: 150px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}
.chien-card4 {
  border-radius: 15px;
  height: calc(100vw /8);
  width: calc(100vw /8);
  max-width: 150px;
  max-height: 150px;
  background-color: #ffffff;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  padding: 15px;
}

.chien-contrindication-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  column-gap: 4em;
  margin: 38px 4em 0;
  align-items: center;

}

.chien-photo2 {
  max-height: 250px;
}

.chien-contrindication-description {
  font-family: 'Poppins';
  margin: 0 4em;
}

.chien-contrindication-description h4 {
  margin: 0 0 1em;
  font-size: 18px;
  font-weight: 500;
}

.chien-contrindication-description ul {
  font-size: 16px;
}

.chien-contrindication-description li {
  margin: 0;
  font-size: 16px;
}

@media (max-width: 700px) {
  .chien-header-description {
    max-width: 80%;
  }
  .chien-contrindication-wrapper {
    flex-direction: column;
    margin: 1em 0 4em;
    row-gap: 2em;
  }
}

@media (max-width: 600px) {
  .chien-header-container {
    padding: 2em;
  }
  .chien-header-description {
    max-width: none;
  }
  .chien-header-description p {
    font-size: 14px;
  }
  .chien-cards-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    column-gap: 3em;
  }
  .chien-card-text {
    max-width: 120px;
  }
  .chien-card-text p {
    font-size: 14px;
  }
  .chien-card-wrapper {
    max-width: 100px;
  }
  .chien-card1 {
    height: 120px;
    width: 120px;
    padding: 5px;
  }
  .chien-card2 {
    height: 120px;
    width: 120px;
    padding: 5px;
  }
  .chien-card3 {
    height: 120px;
    width: 120px;
    padding: 5px;
  }
  .chien-card4 {
    height: 120px;
    width: 120px;
    padding: 5px;
  }
  .chien-contrindication-description {
    margin: 0;
  }
  .chien-contrindication-description h4 {
    font-size: 16px;
  }
  .chien-contrindication-description ul {
    font-size: 14px;
  }
  .chien-contrindication-description li {
    font-size: 14px;
  }
}