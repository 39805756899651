.footer-container {
  background: #662383;
}
.footer-container h1 {
  background-color: #f8e5ff;
  color: #662383;
  text-align: center;
  font-family: Poppins;
  min-height: 2rem;
}
.footer-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
  gap: 10rem;
  padding: 5rem 2rem 7rem 2rem ;
}
.footer-cards{
  display: flex;
  text-align: center;
  align-items: center;
}
.footer-icons {

}
.footer-icons p {
  color: white;
  height: 1rem;
  font-family: Poppins;
  padding-top: 10px;
  font-size: 13px;
}
.footer-icons a {
  color: white;

}


@media (max-width: 960px) {
  .footer-wrapper {
    padding: 3rem 2rem 5rem;
    gap: 5rem;
  }
}
@media (max-width: 650px) {
  .footer-container h1 {
    font-size: 1.5rem;
  }
}


