@import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700,300);

.team-container {
    width:auto;
}
.team-header {
    background-color: #fdfbf7;
    display: flex;
    width: 100%;  
    border-bottom: 2px solid #000000;    

}
.team-header-responsive {
    display: none;
}
@media (max-width: 775px){
    .team-header{
     display: none;
    }
  
    .team-header-responsive {
     display: flex;
     width: 100%; 
     border-bottom: 2px solid #000000;
     background-color: #fdfbf7;
    }
  }
.team-member {
    display: flex;
    flex-direction: row;
    width: 95%;
    gap: 100px;
    margin: 75px 150px 100px 70px;
    align-items: center;
    font-family: Poppins;
}

.team-left-box {
    width: fit-content;
    margin: 1vh;
}
.team-content-abou {
    padding: 15px 15px 20px;
    background: #662383;
    border-radius: 40px 10px;
    margin-top: 0px;
    color: #ffffff;
}
.team-img-abou {
    width: 252px;
    height: 320px;
    border-radius: 15%;
    border: 2px solid #662383;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 5px 5px rgb(153, 153, 153);
    margin: 0 0 1em 0;
}
.team-content-conegero {
    padding: 15px 15px 20px;
    background: #94C11F;
    border-radius: 40px 10px;
    margin-top: 0px;
    color: #ffffff;
}
.team-img-conegero {
    width: 252px;
    height: 320px;
    border-radius: 15%;
    border: 2px solid #94C11F;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 5px 5px rgb(153, 153, 153);
    margin: 0 0 1em 0;
}
.team-rdv-conegero {
    display: flex;
    color: #94C11F;
    padding: 1rem;
    font-size: 2rem;
    text-decoration: underline;
}
.team-rdv-link {
    margin-left: 1rem;
}
.arrow-conegero {
    width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	border-top: 0.2rem solid #94C11F;
	border-right: 0.2rem solid #94C11F;
	transition: all 200ms ease;
    transform: rotate(45deg);
    margin-top: 0.8rem;
}

.arrow-conegero:hover{
	box-shadow: 0.2rem -0.2rem 0 #94C11F;
}
.arrow-conegero:before {
    content: '';
	top: 50%;
	left: 50%;
	transform: translate(-40%,-60%) rotate(45deg);
	width: 200%;
	height: 200%;
}

/*

.arrow span:nth-child(2) {
    animation-delay: -0.2s;
}

.*/

.team-content-leruth {
    padding: 15px 15px 20px;
    background: #37AAE1;
    border-radius: 40px 10px;
    margin-top: 0px;
    color: #ffffff;
}
.team-img-leruth {
    width: 252px;
    height: 320px;
    border-radius: 15%;
    border: 2px solid #37AAE1;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 5px 5px rgb(153, 153, 153);
    margin: 0 0 1em 0;
}
.team-content-paccagnella {
    padding: 15px 15px 20px;
    background: #662383;
    border-radius: 40px 10px;
    margin-top: 0px;
    color: #ffffff;
}
.team-img-paccagnella {
    width: 252px;
    height: 320px;
    border-radius: 15%;
    border: 2px solid #662383;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 5px 5px rgb(153, 153, 153);
    margin: 0 0 1em 0;
}
.team-rdv-paccagnella {
    display: flex;
    color: #662383;
    padding: 1rem;
    font-size: 2rem;
    text-decoration: underline;
}
.team-rdv-link-paccagnella {
    margin-left: 1rem;
    color: #662383;
}
.arrow-paccagnella {
    width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	border-top: 0.2rem solid #662383;
	border-right: 0.2rem solid #662383;
	transition: all 200ms ease;
    transform: rotate(45deg);
    margin-top: 0.8rem;
}

.arrow-paccagnella:hover{
	box-shadow: 0.2rem -0.2rem 0 #662383;
}
.arrow-paccagnella:before {
    content: '';
	top: 50%;
	left: 50%;
	transform: translate(-40%,-60%) rotate(45deg);
	width: 200%;
	height: 200%;
}
.team-content-clerin {
    padding: 15px 15px 20px;
    background: #E71E73;
    border-radius: 40px 10px;
    margin-top: 0px;
    color: #ffffff;
}
.team-img-clerin {
    width: 252px;
    height: 320px;
    border-radius: 15%;
    border: 2px solid #E71E73;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 5px 5px rgb(153, 153, 153);
    margin: 0 0 1em 0;
}
.team-rdv-clerin {
    display: flex;
    color: #E71E73;
    padding: 1rem;
    font-size: 2rem;
    text-decoration: underline;
}
.team-rdv-link-clerin {
    margin-left: 1rem;
    color: #E71E73;
}
.arrow-clerin {
    width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	border-top: 0.2rem solid #E71E73;
	border-right: 0.2rem solid #E71E73;
	transition: all 200ms ease;
    transform: rotate(45deg);
    margin-top: 0.8rem;
}

.arrow-clerin:hover{
	box-shadow: 0.2rem -0.2rem 0 #E71E73;
}
.arrow-clerin:before {
    content: '';
	top: 50%;
	left: 50%;
	transform: translate(-40%,-60%) rotate(45deg);
	width: 200%;
	height: 200%;
}
.team-rdv-moyano {
    display: flex;
    color: #94C11F;
    padding: 1rem;
    font-size: 2rem;
    text-decoration: underline;
}
.team-rdv-link-moyano {
    margin-left: 1rem;
    color: #94C11F;
}
.arrow-moyano {
    width: 1.5rem;
	height: 1.5rem;
	background: transparent;
	border-top: 0.2rem solid #94C11F;
	border-right: 0.2rem solid #94C11F;
	transition: all 200ms ease;
    transform: rotate(45deg);
    margin-top: 0.8rem;
}

.arrow-moyano:hover{
	box-shadow: 0.2rem -0.2rem 0 #94C11F;
}
.arrow-moyano:before {
    content: '';
	top: 50%;
	left: 50%;
	transform: translate(-40%,-60%) rotate(45deg);
	width: 200%;
	height: 200%;
}
.team-content-francelina {
    padding: 15px 15px 20px;
    background: #DEDC00;
    border-radius: 40px 10px;
    margin-top: 0px;
    color: #ffffff;
}
.team-img-francelina {
    width: 252px;
    height: 320px;
    border-radius: 15%;
    border: 2px solid #DEDC00;
    margin: 0 auto;
    overflow: hidden;
    box-shadow: 3px 5px 5px rgb(153, 153, 153);
    margin: 0 0 1em 0;
}

.team-name {
    font-size: 30px;
    font-weight: bold;
    margin: 10px 0 5px 0;
}
.team-profession {
    display: block;
    font-size: 14px;
    font-weight: 900;
    text-transform: uppercase;
}
.cavadias-text {
    display: flex;
    flex-direction: column;
    gap: 1em;
}
.cavadias-text p {
    max-height: 400px;
    /*width: 50%;*/
    padding: 50px;
    background-color: hwb(0 99% 1%);
    border: 1px #000000;
    box-shadow: 2px 2px 8px rgb(199, 199, 199);
    border-radius: 20px;
    font-size: 16px;
    color: #666;
    line-height: 24px;
    white-space: pre-wrap;
    overflow: scroll;
    scrollbar-width: none;
    text-align: justify;
}
.team-text {
    max-height: 500px;
    padding: 50px;
    background-color: hwb(0 99% 1%);
    border: 1px #000000;
    box-shadow: 2px 2px 8px rgb(199, 199, 199);
    border-radius: 20px;
    font-size: 16px;
    color: #666;
    line-height: 24px;
    white-space: pre-wrap;
    overflow: scroll;
    scrollbar-width: none;
    text-align: justify;
}
.team-rdv {
    font-size: 2rem;
    color: #662383;
}
@media only screen and (max-width: 990px){
    .team-member{ margin-bottom: 30px; }
}
@media only screen and (max-width: 359px){
    .team-member .team-img{
        width: 150px;
        height: 150px;
    }
}
@media (max-width: 850px){
    .team-member {
        flex-direction: column;
        gap: 30px;
        margin: auto;
        padding: 20px 20px;
    }
    .team-text {
        padding: 10px 15px;
        min-width: 300px;
        font-size: 15px;
        overflow: scroll;

    }
    .team-name {
        font-size: 20px;
    }
    .team-profession {
        font-size: 12px;
        font-weight: 900;
        text-transform: uppercase;
    }
    .team-rdv-conegero{
        font-size: 1.8rem;
    }
    .arrow-conegero {
        width: 1rem;
        height: 1rem;
        margin-top: 1rem;
    }
    .team-rdv-paccagnella{
        font-size: 1.8rem;
    }
    .arrow-paccagnella {
        width: 1rem;
        height: 1rem;
        margin-top: 1rem;

    }
}
@media (max-width: 500px){
    .team-rdv-conegero {
        font-size: 1.8rem;
    }
    .arrow-conegero {
        width: 1.3rem 
    }
    .team-rdv-paccagnella {
        font-size: 1.8rem;
    }
    .arrow-paccagnella {
        width: 1.3rem;
    }
}