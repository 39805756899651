
/* Actions Cards Component */
.profession-cards-wrapper {
  background: linear-gradient(0.25turn, #fff7eb, #ffffff, #fff7eb);
}
.profession-cards-container{
  margin: auto;
  padding: 60px 40px;
}
.profession-cards-title {
  font-family: Poppins;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  color: #662383;
  margin-bottom: 10px;
}
.profession-cards-title span {
  color: #ff511a;
  display: block;
}
.preventive-cards-description {
  font-family: Poppins;
  font-size: 20px;
  text-align: center;
  line-height: 30px;
  color: #161616;
  margin: 0px 15px 50px;
}
.cards-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; 
  gap: 30px 10px;
}
.card {
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #c7c7c7;
  padding: 22px 15px 20px;
  width: 100%;
  background-color: #ffffff;
  margin-left: auto;
}
.card-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #c7c7c7;
  padding: 22px 15px 20px;
  width: 80%;
  background-color: #ffffff;
}
.card-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 0px 20px #c7c7c7;
  padding: 22px 15px 20px;
  width: 80%;
  background-color: #ffffff;
  margin-left: auto;
}

.card-icon {
  max-width: 200px;
}
.card-description-left {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  text-align: left;
  padding: 0px 15px 0px 30px;
}
.card-description-left p {
  color: #161616;
}
.card-description-left h3 {
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  color: #161616;
  text-align: center;
  margin-bottom: 20px;
}
.card-description-right {
  font-family: Poppins;
  font-size: 16px;
  line-height: 24px;
  color: #161616;
  text-align: left;
  padding: 0px 15px 0px 30px;
}
.card-description-right p {
  color: #161616;
}
.card-description-right h3 {
  font-family: Poppins;
  font-size: 20px;
  line-height: 24px;
  color: #161616;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 520px) {
  .profession-cards-title {
    font-size: 18px;
    line-height: 25px;
    margin: 0px 20px 20px;
  }
  .profession-cards-title span {
    display: inline;
  }
  .preventive-cards-description {
    font-size: 16px;
    line-height: 24px;
    margin: 0px 20px 30px;
  }
  .cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  .card {
    padding: 30px 7px 10px;
    width: 160px;
    align-self: normal;
  }
  .card-title,
  .card-description {
    font-size: 12px;
    line-height: 18px;
  }
  .card-icon {
    width: 60px;
    height: 60px;
  }
  .card-right {
    margin-left: 0%;
  }
  .card-description-left {
    font-size: 15px;
  }
  .card-description-left h3 {
    font-size: 17px;
  }
  .card-description-right {
    font-size: 15px;
    text-align: left;
    word-wrap: inherit;
  }
  .card-description-right h3 {
    font-size: 17px;
  }
}
@media (max-width: 450px) {
  .cards-wrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
  }
  .card {
    padding: 20px 7px 10px;
    width: 160px;
    align-self: normal;
  }
  .card-title,
  .card-description {
    font-size: 12px;
    line-height: 18px;
  }
  .card-icon {
    width: 50px;
    height: 50px;
  }
  .card-right {
    margin-left: 0%;
  }
  .card-description-left {
    font-size: 12px;
    padding: 0 5px;
  }
  .card-description-left h3 {
    font-size: 16px;
  }
  .card-description-right {
    font-size: 12px;
    text-align: left;
    word-wrap: inherit;
    padding: 0 5px;
  }
  .card-description-right h3 {
    font-size: 15px;
  }
}

/* Statistics Cards Component */
.statistics-cards-container {
  background-color: #022282;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: -45px;
}
.statistics-cards-wrapper {
  margin: auto;
  padding: 58px 0px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 995px;
}
.statistics-title {
  font-family: Poppins;
  font-weight: 700;
  font-size: 25px;
  line-height: 31.5px;
  color: #ffffff;
  text-align: center;
  margin: 0px auto 25px;
  max-width: 514px;
}
.statistics-title span {
  color: #1cecba;
}
.statistics-description {
  font-family: Poppins;
  font-weight: 600;
  font-size: 22px;
  line-height: 30.14px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 68px;
}
.statistics {
  display: flex;
  justify-content: center;
  column-gap: 62px;
  flex-wrap: wrap;
  row-gap: 40px;
}
.cards {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 21px;
}
.statistics-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 34px;
  background-color: #ffffff;
  padding: 27px 46px;
  width: 222px;
}
.card-number {
  font-family: Poppins;
  font-weight: 600;
  font-size: 60px;
  line-height: 82px;
  color: #ff511a;
  margin-bottom: 4px;
  display: flex;
}
.card-number span {
  font-weight: 600;
  font-size: 25px;
  line-height: 34.25px;
}
.left-card-reason {
  font-family: Poppins;
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  color: #161616;
  text-align: center;
}
.left-card-reason span {
  color: #ff511a;
}
.right-card-reason {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 27.4px;
  color: #161616;
  text-align: center;
  width: 133%;
}
.right-card-reason span {
  color: #ff511a;
}

.cards-description {
  font-family: Poppins;
  font-weight: 700;
  font-size: 22px;
  line-height: 30.14px;
  color: #ffffff;
  text-align: center;
  margin-top: 40px;
}
.statistics-sources-container {
  display: flex;
  justify-content: end;
  padding-bottom: 36px;
  padding-right: 40px;
}
.statistics-sources {
  font-family: Poppins;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #ffffff;
  margin-top: 40px;
}
@media (max-width: 995px) {
  .statistics-sources-container {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media (max-width: 520px) {
  .statistics-cards-container {
    background-image: none;
  }
  .statistics-cards-wrapper {
    padding: 33px 0px 14px;
  }
  .statistics-title {
    font-size: 18px;
  }
  .statistics-description {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 22px;
  }
  .statistics {
    row-gap: 20px;
  }
  .cards {
    column-gap: 15px;
  }
  .statistics-card {
    padding: 19px 33px;
    width: 160px;
    height: 160px;
  }
  .card-number {
    font-size: 40px;
    line-height: 55px;
    margin-bottom: 3px;
  }
  .card-number span {
    font-size: 18px;
    line-height: 24.66px;
  }
  .left-card-reason {
    font-size: 16px;
    line-height: 22px;
  }
  .right-card-reason {
    font-size: 15px;
    line-height: 20px;
    width: 138%;
  }
  .cards-description {
    font-size: 14px;
    line-height: 19px;
    margin-top: 14px;
  }
  .statistics-sources-container {
    justify-content: start;
  }
  .statistics-sources {
    font-size: 8px;
    margin-top: 20px;
  }
}
@media (max-width: 375px) {
  .statistics-card {
    padding: 19px 28px;
    width: 150px;
    height: 150px;
  }
}
/* Download Guide Component */

.download-guide-content {
  margin: auto;
  padding: 60px 0px 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 995px;
}
.download-guide-content h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 28px;
  line-height: 38px;
  color: #ffffff;
  text-align: center;
  margin-bottom: 16px;
}

.download-guide-content p {
  font-family: Poppins;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 40px;
  text-align: center;
}
.download-guide-content button {
  font-family: Poppins !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  color: #022282 !important;
  background-color: #ffffff !important;
  border-radius: 8px !important;
  box-shadow: unset !important;
  text-transform: none !important;
  padding: 11px 24px !important;
  position: unset !important;
}
.download-guide-icon {
  position: absolute;
  right: 12px;
  bottom: 12px;
}
@media (max-width: 1200px) {
  .download-guide-content {
    padding: 60px 0px 100px;
  }
  .download-guide-icon {
    bottom: 0px;
    right: 8px;
  }
}
@media (max-width: 800px) {
  .download-guide-content {
    padding: 60px 0px 80px;
  }
  .download-guide-icon {
    display: none;
  }
}
@media (max-width: 520px) {
  .download-guide-content {
    padding: 30px 0px 40px;
  }
  .download-guide-content h1 {
    font-size: 18px;
    line-height: 25px;
    margin-bottom: 30px;
    max-width: 335px;
  }
  .download-guide-content p {
    font-size: 12px;
    line-height: 12px;
    max-width: 300px;
  }
  .download-guide-content button {
    font-size: 14px !important;
    padding: 11px 19px !important;
  }
}

/* Sponsors Component */
.sponsors-conatiner {
  /* background-color: #eff6ff; */
}
.sponsors-content {
  margin: auto;
  padding: 20px 0px 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 995px;
}
.sponsors-content h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 30px;
  line-height: 41px;
  color: #022282;
  text-align: center;
  margin-bottom: 45px;
}
.sponsors-icons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 100px;
}
@media (max-width: 1000px) {
  .one {
    order: 1;
  }
}

@media (max-width: 660px) {
  .sponsor-icon {
    width: 200px;
  }
}

@media (max-width: 520px) {
  .sponsors-content h1 {
    font-size: 12px;
    line-height: 16.5px;
    margin-bottom: 25px;
  }
  .sponsors-icons-container {
    gap: 34px;
  }
  .sponsor-icon {
    width: 120px;
    height: 52px;
  }
  .one {
    order: 0;
  }
}
@media (max-width: 427px) {
  .one {
    order: 1;
  }
}

/* Product Header Component */
.product-header-container {
  /* background: #e7f0ff; */
}
.product-header-content {
  margin: auto;
  padding: 133px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1035px;
  column-gap: 43px;
  row-gap: 30px;
}
.product-header-description {
  max-width: 468px;
}
.product-header-description h1 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 44px;
  line-height: 51px;
  color: #022282;
  margin-bottom: 30px;
}
.product-header-description p {
  font-family: Poppins;
  font-size: 18px;
  line-height: 27px;
  color: #161616;
}
.automated-coaching-image {
  width: 467px;
}
.prediction-health-factors-image {
  width: 460px;
}
@media (max-width: 768px) {
  .product-header-content {
    padding: 35px 0px;
  }
}
@media (max-width: 520px) {
  .product-header-content {
    padding: 35px 0px;
  }
  .product-header-description {
    padding: 0px 20px;
  }
  .product-header-description h1 {
    font-size: 30px;
    line-height: 36px;
    margin-bottom: 25px;
  }
  .product-header-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .product-header-content > img {
    width: 335px;
    height: 247px;
  }
  .risk-analysis-image {
    width: 259px;
    height: 200px;
  }
  .prediction-health-factors-image {
    width: 335px;
    height: 274px;
  }
  .automated-coaching-image {
    width: 335px;
    height: 215px;
  }
}
/* Predicta Features Component */
.predicta-features-container {
  /* background-color: #eff6ff; */
}
.predicta-features-content {
  margin: auto;
  padding: 70px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 995px;
}
.predicta-features-content h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 32px;
  line-height: 41.6px;
  color: #022282;
  margin-bottom: 60px;
  text-align: center;
}
.buttons-container {
  display: flex;
  justify-content: center;
  column-gap: 114px;
  row-gap: 35px;
  flex-wrap: wrap;
}
.button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 3px solid #022282;
  border-radius: 8px;
  padding: 8px 10px;
  width: 410px;
  cursor: pointer;
}
.button-infos {
  display: flex;
  align-items: center;
  column-gap: 13px;
}
.icon-wrapper {
  border: 2px solid #022282;
  padding: 3px 5px;
  border-radius: 5px;
}
.predicta-features-icon {
  width: 29px;
  height: 29px;
}
.button h6 {
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 20.7px;
  color: #022282;
}
.predicta-features-content p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #161616;
  margin-top: 100px;
  margin-bottom: 18px;
  text-align: center;
}
.predicta-features-content Button {
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 15px !important;
  line-height: 26px !important;
  color: #ffffff !important;
  background-color: #022282 !important;
  border-radius: 12px !important;
  box-shadow: unset !important;

  text-transform: none !important;
  padding: 11px 23px !important;
}
.predicta-features-content Button:hover,
.download-guide-content button:hover,
.web-fix-an-appointment-button:hover,
.button:hover {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .predicta-features-content {
    padding: 40px 0px;
  }
  .predicta-features-content p {
    padding: 0px 20px;
    margin: 40px 0px 14px;
  }
}
@media (max-width: 520px) {
  .predicta-features-content h1 {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
  .button {
    width: 345px;
    padding: 8px 3px;
  }
  .button-infos {
    column-gap: 10px;
  }
  .predicta-features-icon {
    width: 20px;
    height: 20px;
  }
  .button h6 {
    font-size: 16px;
    line-height: 18px;
  }
  .predicta-features-content p {
    font-size: 14px;
    line-height: 21px;
  }
  .predicta-features-content Button {
    font-size: 14px !important;
    line-height: 26px !important;
    padding: 11px 23px !important;
  }
}
