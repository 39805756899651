.actualites-main-container {
  background: linear-gradient(0.25turn, #fff7eb, #ffffff, #fff7eb);
}
.actualites-page-title {
  padding: 2rem;
}

.actualites-allposts-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  position: relative;
  padding-bottom: 80px;
  padding-top: 0px;
  gap: 3em;
  margin: 3em;
}

.actualites-post-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 35em;
  /* height: 35em; */
  margin-bottom: 30px;
  border-radius: 6px;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  margin-right: 40px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.actualites-posts-wrapper {
    background-color: #fff7eb;
    position: relative;
    width: 100%;
    padding: 60px 30px 50px 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    flex-direction: row;
    margin: auto;
    align-items: center;
  }
.actualites-invit {
  max-width: 50vh;
  max-height: 55vh;
  margin: auto;
}
.actualites-invit-description {
  max-width: 600px;
  margin: auto;
  border-radius: 20px;
  box-shadow: 2px 2px 8px rgb(199, 199, 199);
  background-color: #fffefc;
}

.actualites-invit-description p {
  font-size: 20px;
  font-family: Poppins;
  padding: 50px;
}
.actualites-posts-wrapper2 {
  background-color: #fff7eb;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 50px;
}
.actualites-invit2 {
  max-width: 50vh;
  max-height: 65vh;
  padding-top: 40px;
}

@media only screen and (max-width: 800px) {
  .actualites-main-container h3 {
    font-size: 20px;
  }
  .actualites-invit-description p {
    font-size: 20px;
    text-align: center;
    padding: 20px 20px
  }
  .actualites-posts-wrapper {
    row-gap: 20px;
    padding: 30px 40px;
  }
  .actualites-posts-wrapper2 {
    padding: 0px 40px;
  }

  
}
@media only screen and (max-width: 500px) {
  .actualites-main-container {
    max-width: 100%;
  }
  .actualites-main-container h3 {
    font-size: 16px;
    padding: 0 10px;
  }
  
}
.actualites-page-title {
    font-family: Poppins;
    font-weight: 700;
    text-align: center;
    font-size: 30px;
    line-height: 41px;
    color: #000000;
    padding-bottom: 30px;
    border-bottom: #662383 solid 10px;
    border-radius: 20px;
}



.actualites-title{
  /* height: 20em; */
  padding: 80px 100px;
}

.actualites-title h1{
  font-size: 40px;
  color: #662383;
}

.actualites-title span{
  font-size: 30px;
  color: #1b161d;
  padding-left: 30px;
}

.actualites-post-image {
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 80%;
    border-radius: 6px;
    pointer-events: none;
    margin-left: 15px;
    margin-right: 15px;
    margin-top: -20px;
    border-radius: 6px;
    box-shadow: 0 16px 38px -12px rgba(0, 0, 0, 0.56), 0 4px 25px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
}

.actualites-post-infos {
    padding: 1em 2em;
    margin-bottom: 0px;
    
}

.actualites-post-title {
    font-weight: 700;
    font-family: "Roboto Slab", "Times New Roman", serif;
    margin-top: 5px;
    margin-right: -5px;
    margin-left: -5px;

}

.actualites-post-description {
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 1rem;
    text-align: justify;
    margin-right: -5px;
    margin-left: -5px;
}

.actualites-post-details {
    display: inline-block;
    margin-top: 15px;
    position: relative;
    box-sizing: border-box;
    white-space: nowrap;
    width: 100%;

}

.actualites-post-author {
    font-family: Poppins;
    font-weight: 500;
    font-size: 20px;
    line-height: 15px;
    color: #ff511a;
    float:left; 
    display:inline; 
    width: auto;
    margin-right: -5px;
    margin-left: -5px;
}

.actualites-post-date {
    position: relative;
    font-size: 14px;
    float: right;
    line-height: 15px;
    display: inline; 
    margin-right: -5px;
    margin-left: -5px;
}

.link {
  text-decoration: none;
  text-transform: none;
}

.link:visited {
  color: rgb(0, 0, 0);
}
.link:hover {
  color: rgb(0, 0, 0);
}
.link:active {
  color: teal;
}



/* ARTICLES */

html,
body {
  height: 100%;
}
body {
  font-size: 100%;
  line-height: 1.5;
  font-family: "Poppins";
  background: #fff;
}
.header-container {
  width: 100%;
}
.header-container .left {
  padding: 5em;
  background: #fff7eb;
  text-align: center;
}
.header-container .left .inner {
  position: relative;
  margin: auto;
  padding: 0;
  max-width: 600px;
}
.header-container .left .inner:before {
  content: url('../assets/Expertise-icon.svg');
  padding: 0 6px;
  display: block;
  width: 100%;
  text-align: center;
}

.header-container .left .inner:before {
  margin-bottom: 24px;
}
.header-container .left .inner:after {
  margin-top: 24px;
}
.header-container .left h1 {
  font-family: "Vast Shadow";
  font-size: calc(3em + 1.5vw);
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  color: #313129;
  font-weight: 400;
  margin: 0.5em 0;
}
.header-container .left p {
  font-family: Poppins;
  text-align: center;
  line-height: 1.2;
  color: #696a58;
  font-weight: 400;
  font-size: calc(0.75em + 1vw);
  border-top: 7px solid #696a58;
  border-bottom: 7px solid #696a58;
  padding: 0.75em 0;
}
.accessibility-description {
  padding: 2em;
}
.accessibility-content {
  padding: 5em;
}
.accessibility-cards-container {
  text-align: center;
}
.accessibility-cards-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  justify-content: space-around;
  margin: 20px 20px 0 20px;
  padding: 20px 40px;
  column-gap: 30px;
  row-gap: 30px;
}
.accessibility-cards {
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}
.accessibility-card-icon {
  height: 130px;
  width: 130px;
}
.accessibility-card-title {
  font-family: Poppins;
  padding-top: 10px;
  font-size: 13px;
}


@media only screen and (max-width: 800px) {
  .accessibility-card-icon {
    height: 100px;
    width: 100px;
  }
  .accessibility-card-title {
    padding-top: 10px;
    font-size: 12px;
  }
  .accessibility-content {
    padding: 2em;
  }
  .accessibility-cards-wrapper {
    padding: 20px 20px;
    column-gap: 15px;
    row-gap: 15px;
    margin: 0;
  }
  .actualites-allposts-container {
    flex-direction: column;
    row-gap: 50px;
    align-items: center;
    padding-top: 40px;
  }
  .actualites-post-container {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 40em;   
  }
  .actualites-title {
    padding: 60px 30px;
  }
  .actualites-title h1 {
    font-size: 35px;
  }
  .actualites-title span {
    font-size: 25px;
  }
}
@media only screen and (max-width: 600px) {
  .header-container .left {
    padding: 3em;
    height: 300px;
  }
  .accessibility-card-icon {
    height: 75px;
    width: 75px;
  }
  .accessibility-card-title {
    font-size: 11px;
  }
  .accessibility-description {
    font-size: 12px;
  }
  .actualites-allposts-container {
    padding-top: 80px;
  }
  .actualites-post-container {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 30em;   
  }

}
.accessibility-description p {
  position: relative;
  font-family: Poppins;
  line-height: 1.4;
  color: #222;
  font-weight: 400;
  font-size: calc(0.75em + 0.75vw);
  margin-bottom: calc(0.75em + 0.75vw);
}
.accessibility-description p:first-of-type:first-letter {
  margin: 0 10px;
  padding: 0 3% 0;
  font-size: calc((0.75em + 0.75vw * 1.4) * 2);
  line-height: 1.5;
  float: left;
  color: #f0f0f0;
  font-weight: 700;
  background: #F39200;
  vertical-align: bottom;
}
figure {
  display: block;
  margin: 0;
  padding: 0;
  -webkit-column-span: all;
     -moz-column-span: all;
          column-span: all;
  margin-top: calc(0.75em + 0.75vw);
  background: #c0c0c0;
  background: -webkit-repeating-linear-gradient(225deg, #e0e0e0, #e0e0e0 0.1em /* black stripe */, #fff 0, #fff 0.4em /* blue stripe */);
  background: repeating-linear-gradient(-135deg, #e0e0e0, #e0e0e0 0.1em /* black stripe */, #fff 0, #fff 0.4em /* blue stripe */);
}
figure img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  display: block;
  margin: 0 auto;
}
figure figcaption {
  font-size: calc(0.75em + 0.75vw);
  display: block;
  color: #999;
  padding: 6px 0 calc(0.75em + 0.75vw * 2);
  text-align: right;
  background: #fff;
}
figure figcaption:before {
  content: '\f083';
  font-family: FontAwesome;
  padding-right: 6px;
}





.article-list {
  color:#313437;
  background-color:#fff;
}

.article-list p {
  color:#7d8285;
}

.article-list h2 {
  font-weight:bold;
  margin-bottom:40px;
  padding-top:40px;
  color:inherit;
}

@media (max-width:767px) {
  .article-list h2 {
    margin-bottom:25px;
    padding-top:25px;
    font-size:24px;
  }

}

.article-list .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto;
}

.article-list .intro p {
  margin-bottom:0;
}

.article-list .articles {
  padding-bottom:40px;
}

.article-list .item {
  padding-top:50px;
  min-height:425px;
  text-align:center;
}

.article-list .item .name {
  font-weight:bold;
  font-size:16px;
  margin-top:20px;
  color:inherit;
}

.article-list .item .description {
  font-size:14px;
  margin-top:15px;
  margin-bottom:0;
}

.article-list .item .action {
  font-size:24px;
  width:24px;
  margin:22px auto 0;
  line-height:1;
  display:block;
  color:#4f86c3;
  opacity:0.85;
  transition:opacity 0.2s;
  text-decoration:none;
}

.article-list .item .action:hover {
  opacity:1;
}

