.home-header-container {
  background: linear-gradient(0.25turn, #fff7eb, #ffffff, #fff7eb);
  /* background-color: #f8ecfd;
  */ 
  background-image: url("../assets/Casco4.jpg");
  background-size:cover;
  padding-bottom: 40px;
}
@media (max-width: 1580px) {
  .home-header-container {
    background-image: url("../assets/Casco3.jpg");
  }
  .home-header-description h1 span {
    color: #94C11F !important;
  }
}
@media (min-width: 1000px) {
  .home-header-image {
    display: none !important;
  }
}
@media (max-width: 1200px) {
  .home-header-container {
    background-image: url("../assets/Casco1.jpg");
  }
  .home-header-description {
    margin: 0 !important;
  }
  .home-header-description h1 {
    padding: 0.5em;
  }
}
@media (max-width: 1000px) {
  .home-header-container {
    background-image: none;
    background: linear-gradient(0.25turn, #fff7eb, #ffffff, #fff7eb);
    padding-bottom: 2em;
  }
  .home-header {
    justify-content: center !important;
  }
  .home-header-image {
    margin: 2em 0 0 !important;

  }
  .home-header-description h1 {
    color: #070707 !important;
  }
  .home-header-description p {
    color: #070707 !important;
  }
  .profession-cards-container {
    background-color: #fff;
    padding: 30px 40px;
  }
}
  /*background: linear-gradient(0.25turn, rgba(255,255,255,0.99),
    rgba(255,255,255,0.93), 
    rgba(255,255,255,0.80)),
    url('../assets/background-image2.png');
  background-size: 1035px 430px;
  background-position: 65%;
  background-repeat: no-repeat; */
.home-header {
  display: flex;
  margin: auto;
  padding: 70px 20px 0px;
  justify-content: end;
  flex-wrap: wrap;
  max-width: 1035px;
  column-gap: 60px;
  row-gap: 30px;
}
.home-header-description {
  max-width: 500px;
  margin: 2em 0 0 3em;
  align-self: end;
}
.home-header-description h1 {
  font-family: Poppins;
  font-weight: 450;
  font-size: 40px;
  line-height: 48px;
  color: #ffffff;
  margin-bottom: 50px;
}
.home-header-description p {
  font-family: Poppins;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  color: #ffffff;
  text-align: justify;
}
.home-header-description h1 span,
.home-header-description p span {
  color: #662383;
  font-weight: 550;
}
.home-header-image {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-header-image Button {
  border-radius: 8px !important;
  background-color: transparent !important;
  border: 2px solid #ff511a !important;
  color: #022282 !important;
  text-transform: none !important;
  font-family: Montserrat !important;
  font-size: 14px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
  padding: 0px 14px;
  box-shadow: none !important;
}
.home-header-image Button:hover {
  color: #ffffff !important;
  background-color: #ff511a !important;
}

.home-CASCO-container {
  /*background: linear-gradient(
    to left,
      #f8ecfd,
      #ffffff
    );
    */
   background-color: #ffffff;
}
.home-CASCO {
  display: flex;
  align-items: center;
  margin: auto;
  padding: 30px 20px 0px 30px;
  margin-bottom: 40px;
  justify-content: center;
  vertical-align: top;

  flex-wrap: wrap;
  max-width: 1035px;
  column-gap: 60px;
  row-gap: 30px;
  text-align: justify;
}
.home-CASCO-title {
  height: 100px;
  font-size: 40px;
  text-align: center;
  padding-top: 30px;
  font-family: Poppins;
  font-weight: 600;
  color: #662383;
}
.home-CASCO-title h1 {
  height: 30px;
  font-size: 25px;
  text-align: center;
  font-family: Poppins;
  font-weight: 600;
  padding-top: 10px;
  color: #000;
}
.home-CASCO-description {
  max-width: 700px;
  font-family: Poppins;
  padding: 0px 30px 0px 15px;
}
.home-CASCO-span {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  margin: auto;
  font-size: 18px;
  text-align: center;
  font-family: Poppins;
  background-color: #fff7eb;
  border-radius: 12px;
  padding: 5px;
  overflow-wrap: break-word;
}
@media (max-width: 600px) {
  .home-CASCO-span {
    font-size: 15px;
  }
  .home-header-description p {
    text-align: start;
  }
}
.home-CASCO-span p {
}
.home-CASCO-span span {
  font-weight: bold;
  
}
.home-CASCO-description h1 {
  font-family: Poppins;
  font-weight: 450;
  font-size: 40px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 50px;
}
.home-CASCO-description p {
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #161616;
}

.home-CASCO-description h1 span,
.home-CASCO-description p span {
  color: #000000;
  font-weight: bold;
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 2px solid #000000;
  max-width: 80%;
  margin-left: 10%;
  margin-top: 50px;
  margin-bottom: 20px;
}

.home-CASCO-split2 {
  max-width: 400px;
  padding: 70px 0px 30px 0px;
  font-size: 18px;
}
.home-CASCO-split2 p {
  font-family: Poppins;
  font-weight: 300;
  line-height: 30px;
  color: #161616;
}
.home-CASCO-split2 span {
  color: #662383;
  font-weight: bold;
}
.home-CASCO-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 200px;
}
@media (max-width: 520px) {
  .home-CASCO {
    margin: 0px 0px 10px;
    padding: 10px 20px;
    row-gap: 0px;
  }
  .home-CASCO-title {
    font-size: 18px;
    line-height: 25px;
  }
  .home-CASCO-title h1 {
    font-size: 18px;
    line-height: 25px;
    margin: 0px 20px 20px;
  }
  .home-CASCO-description p {
    font-size: 15px;
  }
  .home-CASCO-split2 {
    font-size: 15px;
    padding: 10px 0px 0px;
    margin-top: 40px;
  }

}

.map-container {
  background: #ffffff;
  padding-top: 100px;
}

.map-title {
  font-family: Poppins;
  font-weight: 600;
  height: 60px;
  font-size: 30px;
  text-align: center;
  color: #662383;
}

.map-content {
  display: flex;
  justify-content: space-around;
  max-width: 1035px;
  align-items: center;
  column-gap: 20px;
  margin: auto;
  padding: 20px 10px 60px;
}

.map-location {
  height: 400px;
  width: 60%;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.map-description{
  max-width: 35%;
}

.map-description h1 {
  font-family: Poppins;
  font-weight: 500;
  font-size: 33px;
  line-height: 48px;
  color: #000000;
  margin-bottom: 50px;
}
.map-description p {
  font-family: Poppins;
  font-weight: 300;
  font-size: 18px;
  line-height: 30px;
  color: #161616;
}
.map-description h1 span,
.map-description p span {
  color: #662383;
}

@media (max-width: 700px) {
  .home-header {
    padding: 35px 20px 0px;
    margin-bottom: 20px;
    row-gap: 0px;
  }
  .map-content {
    display: flex;
    flex-direction: column;
  }
  .map-title {
    font-size: 20px;
  }
  .map-description{
    max-width: 80%;
    padding-top: 20px;
  }
  .map-description p {
    font-size: 15px;
    
  }
  .map-description h1 {
    font-size: 22px;
    line-height: 48px;
    color: #662383;
    margin-bottom: 0px;
  }
  .home-header-description h1 {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 25px;
  }
  .home-header-description p {
    font-size: 16px;
    line-height: 24px;
  }
  .home-header-image svg {
    width: 355px;
    height: 300px;
  }
  .home-header-image Button {
    font-size: 12px !important;
    line-height: 24px !important;
  }
}

/*slider */
.slider_title {
  font-size: 2vw;
  color: #fc5e42;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 2vw;
  padding-top: 50px;
}
.card-wrapper {
  position: relative;
  width: 95%;
  height:400px;
  padding: 20px 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.card-wrapper:active, .card-wrapper:hover, .card-wrapper:focus {
  outline: none;
  border: none;
}
.card-slidder {
  position: absolute;
  top: 0;
  left: 0;
  width: 90%;
  height: 100%;
  z-index: 2;
  background-color: #000;
  -webkit-transition: .5s;
  transition: .5s;
}
.card-image-slidder {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.card-slidder:hover img {
  opacity: .4;
  -webkit-transition: .5s;
  transition: .5s;
}
.card-slidder:hover .card-image {
  -webkit-transform: translateY(-100px);
          transform: translateY(-100px);
  -webkit-transition: all .9s;
  transition: all .9s;
}
.card-slidder:hover .social-icons li a {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  opacity: 1;
}
.card-slidder:hover li:nth-child(1) a {
  -webkit-transition-delay: .1s;
          transition-delay: .1s;
}
.card-slidder:hover li:nth-child(2) a {
  -webkit-transition-delay: .2s;
          transition-delay: .2s;
}
.card-slidder:hover li:nth-child(3) a {
  -webkit-transition-delay: .3s;
          transition-delay: .3s;
}
.card-slidder:hover li:nth-child(4) a {
  -webkit-transition-delay: .4s;
          transition-delay: .4s;
}
.details {
  position: absolute;
  bottom: 100px;
  left: 0;
  background: #fff;
  width: 100%;
  height: 120px;
  z-index: 1;
  padding: 10px;
  -webkit-transition: .6s;
  transition: .6s;
}
.details h2 {
  margin: 30px 0;
  padding: 0;
  text-align: center;
}
.details h2 .job-title {
  font-size: 16px;
  line-height: 2;
  color: #333;
  font-weight: 300;
  display: block;
}
.card-slidder:hover .details {
  bottom: 0;
}
.carousel .slick-dots {
  bottom: -2.5vw;
}
.carousel .slick-dots li button {
  width: 20px;
  height: 20px;
  border: 1px solid #fff;
  border-radius: 20px;
}
.carousel .slick-dots li button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 10px;
  height: 10px;
  border-radius: 10px;
  content: "";
  text-align: center;
  opacity: .5;
  color: #000;
  background-color: #fff;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
}
.carousel .slick-dots li.slick-active button, .carousel .slick-dots li:hover button {
  border-color: tomato;
}
.carousel .slick-dots li.slick-active button:before, .carousel .slick-dots li:hover button:before {
  background-color: tomato;
  opacity: 1;
}
.carousel .slick-prev, .carousel .slick-next {
  width: 50px;
  height: 50px;
  background-color: #fff;
}
.carousel .slick-prev:hover, .carousel .slick-prev:focus, .carousel .slick-next:hover, .carousel .slick-next:focus {
  color: #fff;
  outline:
 none;
  background: #fff;
}
.carousel .slick-prev:before, .carousel .slick-next:before {
  color: #000;
  font-family: "Raleway", sans-serif;
}
.carousel .slick-next {
  right: -55px;
}
.carousel .slick-prev {
  left: -55px;
}

/* Predicta Team */
.predicta-team {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.95) 0,
      rgba(255, 255, 255, 0.95) 0
    ),
    url("../assets/background-image2.png") center;
  padding-top: 10%;
  background-size: contain;
}
/* Download Guide Home Component */
.download-guide-home-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  padding: 60px 20px 80px;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1035px;
}
.download-guide-home-content h1 {
  font-family: Poppins;
  font-weight: 600;
  font-size: 25px;
  line-height: 34px;
  text-align: center;
  color: #022282;
  margin-bottom: 60px;
}
.download-guide-home-content h1 span {
  color: #ff511a;
}
.download-guide-home-content p {
  font-family: Poppins;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #161616;
  margin-bottom: 20px;
}
.download-guide-home-content Button {
  border-radius: 8px !important;
  background-color: #022282 !important;
  color: #fff !important;
  text-transform: none !important;
  font-family: Montserrat !important;
  font-size: 15px !important;
  line-height: 26px !important;
  font-weight: 700 !important;
  padding: 11px 10px;
  box-shadow: none !important;
  position: unset;
}

.download-guide-home-content Button:hover {
  background-color: #022282 !important;
  color: white !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}



.map-description {
  height: "400px";
  width: "50vw";
  display: "flexDirection";
  margin: "0";
  align-items: "center";
  align-content: "center";
  flex-wrap: "wrap";
}

/* -------------------------------- 

Chronology slide
-------------------------------- */

@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro);
*, *::after, *::before {
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  font-family: "Source Sans Pro", sans-serif;
  color: #383838;
  /* background-color: #faecff; */
}

a {
  color: #7b9d6f;
  text-decoration: none;
}
.cd-horizontal-timeline {
  opacity: 1;
  margin: 2em auto;
  -webkit-transition: opacity 0.2s;
  -moz-transition: opacity 0.2s;
  transition: opacity 0.2s;
}
.cd-horizontal-timeline::before {
  /* never visible - this is used in jQuery to check the current MQ */
  content: 'mobile';
  display: none;
}
.cd-horizontal-timeline.loaded {
  /* show the timeline after events position has been set (using JavaScript) */
  opacity: 1;
}
.timeline {
  position: relative;
  height: 100px;
  width: 90%;
  max-width: 800px;
  margin: 0 auto;
}
.events-wrapper {
  position: relative;
  height: 100%;
  margin: 0 40px;
  overflow: hidden;
}
.cd-horizontal-timeline .events-wrapper::after, .cd-horizontal-timeline .events-wrapper::before {
  /* these are used to create a shadow effect at the sides of the timeline */
  content: '';
  position: absolute;
  z-index: 2;
  top: 0;
  height: 100%;
  width: 20px;
}
.cd-horizontal-timeline .events-wrapper::before {
  left: 0;
  background-image: -webkit-linear-gradient( left , #00ffd5, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to right, #00c3ff, rgba(248, 248, 248, 0));
}
.cd-horizontal-timeline .events-wrapper::after {
  right: 0;
  background-image: -webkit-linear-gradient( right , #f8f8f8, rgba(248, 248, 248, 0));
  background-image: linear-gradient(to left, #f8f8f8, rgba(248, 248, 248, 0));
}
.cd-horizontal-timeline .events {
  /* this is the grey line/timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 49px;
  height: 2px;
  /* width will be set using JavaScript */
  background: red;
  -webkit-transition: -webkit-transform 0.4s;
  -moz-transition: -moz-transform 0.4s;
  transition: transform 0.4s;
}
.cd-horizontal-timeline .filling-line {
  /* this is used to create the green line filling the timeline */
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: green;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  -o-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  -moz-transform-origin: left center;
  -ms-transform-origin: left center;
  -o-transform-origin: left center;
  transform-origin: left center;
  -webkit-transition: -webkit-transform 0.3s;
  -moz-transition: -moz-transform 0.3s;
  transition: transform 0.3s;
}
.cd-horizontal-timeline .events a {
  position: absolute;
  bottom: 0;
  z-index: 2;
  text-align: center;
  font-size: 1.3rem;
  padding-bottom: 15px;
  color: #ff00c8;
  /* fix bug on Safari - text flickering while timeline translates */
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.cd-horizontal-timeline .events a::after {
  /* this is used to create the event spot */
  content: '';
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -5px;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  border: 2px solid #10adc2;
  background-color: #f8f8f8;
  -webkit-transition: background-color 0.3s, border-color 0.3s;
  -moz-transition: background-color 0.3s, border-color 0.3s;
  transition: background-color 0.3s, border-color 0.3s;
}
.no-touch .cd-horizontal-timeline .events a:hover::after {
  background-color: #7b9d6f;
  border-color: #7b9d6f;
}
.cd-horizontal-timeline .events a.selected {
  pointer-events: none;
}
.cd-horizontal-timeline .events a.selected::after {
  background-color: #7b9d6f;
  border-color: #7b9d6f;
}
.cd-horizontal-timeline .events a.older-event::after {
  border-color: #7b9d6f;
}
@media only screen and (min-width: 1100px) {
  .cd-horizontal-timeline {
    margin: 6em auto;
  }
  .cd-horizontal-timeline::before {
    /* never visible - this is used in jQuery to check the current MQ */
    content: 'desktop';
  }
}

.cd-timeline-navigation a {
  /* these are the left/right arrows to navigate the timeline */
  position: absolute;
  z-index: 1;
  top: 50%;
  bottom: auto;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  height: 34px;
  width: 34px;
  border-radius: 50%;
  border: 2px solid #dfdfdf;
  /* replace text with an icon */
  overflow: hidden;
  color: transparent;
  text-indent: 100%;
  white-space: nowrap;
  -webkit-transition: border-color 0.3s;
  -moz-transition: border-color 0.3s;
  transition: border-color 0.3s;
}
.cd-timeline-navigation a::after {
  /* arrow icon */
  content: '';
  position: absolute;
  height: 16px;
  width: 16px;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  background: url(../assets/cancel.svg) no-repeat 0 0;
}
.cd-timeline-navigation a.prev {
  left: 0;
  -webkit-transform: translateY(-50%) rotate(180deg);
  -moz-transform: translateY(-50%) rotate(180deg);
  -ms-transform: translateY(-50%) rotate(180deg);
  -o-transform: translateY(-50%) rotate(180deg);
  transform: translateY(-50%) rotate(180deg);
}
.cd-timeline-navigation a.next {
  right: 0;
}
.no-touch .cd-timeline-navigation a:hover {
  border-color: #7b9d6f;
}
.cd-timeline-navigation a.inactive {
  cursor: not-allowed;
}
.cd-timeline-navigation a.inactive::after {
  background-position: 0 -16px;
}
.no-touch .cd-timeline-navigation a.inactive:hover {
  border-color: #e5ff00;
}

.cd-horizontal-timeline .events-content {
  position: relative;
  width: 100%;
  margin: 2em 0;
  overflow: hidden;
  -webkit-transition: height 0.4s;
  -moz-transition: height 0.4s;
  transition: height 0.4s;
}
.cd-horizontal-timeline .events-content li {
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
  transform: translateX(-100%);
  padding: 0 5%;
  opacity: 0;
  -webkit-animation-duration: 0.4s;
  -moz-animation-duration: 0.4s;
  animation-duration: 0.4s;
  -webkit-animation-timing-function: ease-in-out;
  -moz-animation-timing-function: ease-in-out;
  animation-timing-function: ease-in-out;
}
.cd-horizontal-timeline .events-content li.selected {
  /* visible event content */
  position: relative;
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}
.cd-horizontal-timeline .events-content li.enter-right, .cd-horizontal-timeline .events-content li.leave-right {
  -webkit-animation-name: cd-enter-right;
  -moz-animation-name: cd-enter-right;
  animation-name: cd-enter-right;
}
.cd-horizontal-timeline .events-content li.enter-left, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-name: cd-enter-left;
  -moz-animation-name: cd-enter-left;
  animation-name: cd-enter-left;
}
.cd-horizontal-timeline .events-content li.leave-right, .cd-horizontal-timeline .events-content li.leave-left {
  -webkit-animation-direction: reverse;
  -moz-animation-direction: reverse;
  animation-direction: reverse;
}
.cd-horizontal-timeline .events-content li > * {
  max-width: 800px;
  margin: 0 auto;
}
.cd-horizontal-timeline .events-content h2 {
  font-weight: bold;
  font-size: 2.6rem;
  font-family: "Playfair Display", serif;
  font-weight: 700;
  line-height: 1.2;
}
.cd-horizontal-timeline .events-content em {
  display: block;
  font-style: italic;
  margin: 10px auto;
}
.cd-horizontal-timeline .events-content em::before {
  content: '- ';
}
.cd-horizontal-timeline .events-content p {
  font-size: 1.4rem;
  color: #ff0000;
}
.cd-horizontal-timeline .events-content em, .cd-horizontal-timeline .events-content p {
  line-height: 1.6;
}
@media only screen and (min-width: 768px) {
  .cd-horizontal-timeline .events-content h2 {
    font-size: 7rem;
  }
  .cd-horizontal-timeline .events-content em {
    font-size: 2rem;
  }
  .cd-horizontal-timeline .events-content p {
    font-size: 1.8rem;
  }
}

@-webkit-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-right {
  0% {
    opacity: 0;
    -moz-transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-right {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}
@-webkit-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
  }
}
@-moz-keyframes cd-enter-left {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -moz-transform: translateX(0%);
  }
}
@keyframes cd-enter-left {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    -moz-transform: translateX(0%);
    -ms-transform: translateX(0%);
    -o-transform: translateX(0%);
    transform: translateX(0%);
  }
}

