.nav-bar-container {
  position: sticky;
  top: 0em;
  left: 0;
  z-index: 9;
  width: 100%;
}
.top-nav-bar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 4em;
  column-gap: 100px;
  background-color: #BF1E2E;
}
.top-nav-bar p {
  font-family: Poppins;
  font-size: 20px;
  line-height: 18px;
  color: #fff;
  text-align: center;
  font-weight: 600;
}
.download-guide-bttn {
  font-size: 15px !important;
  font-weight: 600 !important;
  text-decoration: underline !important;
  border: solid 1px !important;
}
.download-guide-bttn-top {
  color: #FFF !important ;
  font-family: 'Poppins' !important;
  font-weight: bold !important;
  font-size: 14px !important;
  line-height: 26px !important;
  text-align: center !important;
  margin: 10px 0px !important;
  text-transform: unset !important;
}
.bottom-nav-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 4% 20px 5%;
  background-color: #ffffff;
}
.bottom-nav-bar-right {
  display: flex;
  align-items: center;
}
.select-nav-bar div {
  padding: 3px 32px !important;
}

 .logo-scale-up-center{
  /* animation:logo-scale-up-center 1.9s cubic-bezier(.39,.575,.565,1.000) 0.5s infinite both
}
 @keyframes logo-scale-up-center{0%{transform:scale(.5)}100%{transform:scale(1)}}  */
 } 

.link {
  margin: 0 14px;
  display: inline-flex;
  font-family: Poppins;
  font-weight: 700;
  font-size: 18px;
  line-height: 27px;
  color: #757575;
  cursor: pointer;
}
.link:hover {
  color: #662383;
  border-bottom: none;
}
.active-link {
  color: #161616;
  border-bottom: 3px solid #f39200;
}
.product-item {
  font-family: Poppins;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  color: #662383;
}
.test-predicta-bttn {
  background-color: #662383 !important;
  border-radius: 8px !important;
  font-family: Montserrat !important;
  font-weight: 700 !important;
  font-size: 16px !important;
  line-height: 26px !important;
  color: #ffffff !important;
  margin-left: 35px !important;
  text-transform: none !important;
  box-shadow: none !important;
}
.test-predicta-bttn:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: #1565c0;
  box-shadow: none !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  transform: scale(1.1);
}

.responsive-bottom-nav-bar,
.responsive-nav-bar-links {
  display: none;
}
@media (max-width: 1100px) {
  .bottom-nav-bar {
    padding: 20px 2% 20px 3%;
  }
  .test-predicta-bttn {
    margin-left: 25px !important;
  }
}
@media (max-width: 1000px) {
  .bottom-nav-bar {
    padding: 20px 1%;
  }
  .link {
    margin: 0 10px;
  }
  .test-predicta-bttn {
    margin-left: 20px !important;
  }
}
@media (max-width: 960px) {
  .top-nav-bar p {
    max-width: 58%;
  }
  .download-guide-bttn {
    font-size: 15px !important;
    padding: 6px 6px !important;
  }
  .bottom-nav-bar {
    display: none;
  }
  .responsive-bottom-nav-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 3%;
    background-color: #ffffff;
  }
  .responsive-nav-bar-icons {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }
  .nav-bar-menu-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
  }
  .responsive-nav-bar-icon {
    cursor: pointer;
    height: 50px;
    width: 150px;
  }
  .responsive-test-predicta-bttn {
    background-color: #662383 !important;
    border-radius: 4px !important;
    font-family: Montserrat !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 26px !important;
    color: #ffffff !important;
    padding: 5px 10px !important;
    text-transform: none !important;
  }
  .responsive-nav-bar-links {
    display: flex;
    flex-direction: column;
    align-items: center;
    list-style: none;
    position: absolute;
    background: rgba(92, 48, 143, 0.78);
    top: 100%;
    left: 0px;
    height: 100vh;
    width: 100%;
    padding-top: 10px;
  }
  .cancel-responsive-nav-bar {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 30px;
    color: #ffffff;
  }
  .responsive-nav-bar-links li {
    font-family: Poppins;
    font-weight: 700;
    font-size: 18px;
    line-height: 27px;
    color: #ffffff;
    cursor: pointer;
    text-align: center;
    margin-bottom: 60px;
  }
  .responsive-nav-bar-links li:hover {
    border-bottom: 2px solid #ffffff;
  }
}
@media (max-width: 680px) {
  .top-nav-bar p {
    font-size: 15px;
    line-height: 12px;
  }
  .download-guide-bttn {
    font-size: 12px !important;
    line-height: 20px !important;
  }
  
}
@media (max-width: 520px) {
  .top-nav-bar {
    column-gap: 15px;
  }
  .top-nav-bar,
  .responsive-bottom-nav-bar {
    padding: 10px 3%;
  }
  .responsive-nav-bar-icons {
    column-gap: 10px;
  }
  .nav-bar-menu-icon {
    width: 24px;
    height: 24px;
  }
  .responsive-nav-bar-icon {
    height: 35px;
    width: 124px;
  }
  .responsive-test-predicta-bttn {
    font-size: 12px !important;
    line-height: 26px !important;
    color: #ffffff !important;
    padding: 5px 10px !important;
  }
  .download-guide-bttn {
    padding: 2px 3px !important;
  }
}
@media (max-width: 375px) {
  .top-nav-bar {
    column-gap: 10px;
  }
  .top-nav-bar p {
    max-width: 55%;
  }
  .download-guide-bttn {
    font-size: 8px !important;
    line-height: 18px !important;
  }
}
