.accessibilite-body {
  height: 100%;
  font-size: 100%;
  line-height: 1.5;
  font-family: "Poppins";
  background: #fff;
}
.accessibilite-body:after {
  content: ' ';
  display: block;
  width: 100%;
  text-align: center;
  font-size: 8em;
  color: #e0e0e0;
}
*,
*:before,
*:after {
  box-sizing: border-box;
}
.accessibilite-header-container {
  width: 100%;
  min-width: 280px;
  margin-bottom: -250px;
  max-height: 500px;
}
.accessibilite-right {
  background-image: url('../assets/photo-nature-min.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  max-height: 500px;
}
.accessibilite-left {
  padding: 2em;
  background: #f0f0f0;
  max-height: 500px;
}
.accessibilite-inner {
  position: relative;
  margin: auto;
  padding: 0;
  max-width: 400px;
  max-height: 500px;

}
.accessibilite-inner:before {
  content: url('../assets/Expertise-icon.svg');
  font-family: Poppins;
  color: #91927c;
  font-size: 3em;
  padding: 0 6px;
  display: block;
  width: 100%;
  text-align: center;
}
.accessibilite-inner:after {
  content: url();
  font-family: FontAwesome;
  color: #91927c;
  font-size: 3em;
  padding: 0 6px;
  display: block;
  width: 100%;
  text-align: center;
}
.accessibilite-inner:before {
  margin-bottom: 24px;
}
.accessibilite-inner:after {
  margin-top: 24px;
}
.accessibilite-left h1,
.accessibilite-left h3,
.accessibilite-left h4 {
  font-family: "Playfair Display", serif;
  font-size: calc(1em + 3.5vw);
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  color: #696a58;
  font-weight: 400;
}
.accessibilite-left h2 {
  font-family: "Vast Shadow";
  font-size: calc(1em + 1.5vw);
  text-transform: uppercase;
  text-align: center;
  line-height: 1;
  color: #696a58;
  font-weight: 400;
  margin: 0.5em 0;
}
.accessibilite-left h3 {
  font-size: calc(1.25em + 1vw);
  margin: 0.75em 0 0.5em;
}
.accessibilite-left h4 {
  font-size: calc(0.8em + 1vw);
  margin: 0.5em;
  position: relative;
}
.accessibilite-left h4:before {
  content: '\f083';
  font-family: FontAwesome;
  padding-right: 6px;
}
.accessibilite-left p {
  font-family: "Playfair Display", serif;
  text-align: center;
  line-height: 1.2;
  color: #696a58;
  font-weight: 400;
  font-size: calc(0.75em + 1vw);
  border-top: 7px solid #696a58;
  border-bottom: 7px solid #696a58;
  padding: 0.75em 0;
}
@media only screen and (min-width: 800px) {
  .accessibilite-header-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh;
  }
  .accessibilite-header-container .left,
  .accessibilite-header-container .right {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    width: 50%;
  }
  .accessibilite-header-container .right img {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center center;
       object-position: center center;
    min-height: 100vh;
    min-width: 100%;
  }
  .accessibilite-header-container .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .accessibilite-header-container .left .inner {
    max-width: 400px;
  }
}
.accessibilite-article-container {
  padding: 2em;
}
@media only screen and (min-width: 800px) {
  .accessibilite-article-container {
    -webkit-columns: 2;
       -moz-columns: 2;
            columns: 2;
    -webkit-column-gap: 3rem;
       -moz-column-gap: 3rem;
            column-gap: 3rem;
  }
}
.accessibilite-article-container p {
  font-family: serif;
  line-height: 1.4;
  color: #222;
  font-weight: 400;
  font-size: calc(0.75em + 0.75vw);
  margin-bottom: calc(0.75em + 0.75vw);
  widows: 3;
  orphans: 3;
}
.accessibilite-article-container p:first-of-type:first-letter {
  margin: 1% 3% -2px 0;
  padding: 0 5% 0;
  font-size: calc((0.75em + 0.75vw * 1.4) * 2.75);
  line-height: 1.5;
  float: left;
  color: #f0f0f0;
  font-weight: 700;
  background: tomato;
  vertical-align: bottom;
}
figure {
  display: block;
  margin: 0;
  padding: 0;
  -webkit-column-span: all;
     -moz-column-span: all;
          column-span: all;
  margin-top: calc(0.75em + 0.75vw);
  background: #c0c0c0;
  background: -webkit-repeating-linear-gradient(225deg, #e0e0e0, #e0e0e0 0.1em /* black stripe */, #fff 0, #fff 0.4em /* blue stripe */);
  background: repeating-linear-gradient(-135deg, #e0e0e0, #e0e0e0 0.1em /* black stripe */, #fff 0, #fff 0.4em /* blue stripe */);
}
figure img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
  display: block;
  margin: 0 auto;
}
figure figcaption {
  font-size: calc(0.75em + 0.75vw);
  display: block;
  color: #999;
  padding: 6px 0 calc(0.75em + 0.75vw * 2);
  text-align: right;
  background: #fff;
}
figure figcaption:before {
  content: '\f083';
  font-family: FontAwesome;
  padding-right: 6px;
}





.article-list {
  color:#313437;
  background-color:#fff;
}

.article-list p {
  color:#7d8285;
}

.article-list h2 {
  font-weight:bold;
  margin-bottom:40px;
  padding-top:40px;
  color:inherit;
}

@media (max-width:767px) {
  .article-list h2 {
    margin-bottom:25px;
    padding-top:25px;
    font-size:24px;
  }
}

.article-list .intro {
  font-size:16px;
  max-width:500px;
  margin:0 auto;
}

.article-list .intro p {
  margin-bottom:0;
}

.article-list .articles {
  padding-bottom:40px;
}

.article-list .item {
  padding-top:50px;
  min-height:425px;
  text-align:center;
}

.article-list .item .name {
  font-weight:bold;
  font-size:16px;
  margin-top:20px;
  color:inherit;
}

.article-list .item .description {
  font-size:14px;
  margin-top:15px;
  margin-bottom:0;
}

.article-list .item .action {
  font-size:24px;
  width:24px;
  margin:22px auto 0;
  line-height:1;
  display:block;
  color:#4f86c3;
  opacity:0.85;
  transition:opacity 0.2s;
  text-decoration:none;
}

.article-list .item .action:hover {
  opacity:1;
}
