.lmj-banner {
	color: black;
	text-align: center;
	padding: 32px;
	border-bottom: solid 3px black;
	display: grid;
	align-items: center;
}

.lmj-title {
	padding-left: 0px;
  	text-align: center;
}

.lmj-logo {
	height: 350px;
	width: 430px;
}
.lmj-logo-2 {
	height: 250px;
	width: 358px;
	margin: 10px;

}

@media (max-width: 767px) {
	.lmj-logo {
		padding: 0px 30px 0px 30px;
	}
}

