.slider-container {
  max-width: 1035px;
  height: 800px;
  margin: auto;
  position: relative;
  overflow: hidden;
  padding: 20px;
  overflow: visible;
}
.slider-container-header {
  font-family: Poppins;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
  line-height: 41px;
  color: #662383;
  padding: 20px
}
.slider-container-header h1 {
  font-weight: 600;
  color: #662383;
}
.slider-container-header h4 {
  font-weight: 500;
  color: #000000;
}
.slider-wrapper {
  width: 100%;
  height: 90%;
  padding-top: 0px;
}
.left-arrow {
  position: absolute;
  top: 50%;
  left: -20px;
  font-size: 3rem;
  color: #000;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}
.right-arrow {
  position: absolute;
  top: 50%;
  right: -20px;
  font-size: 3rem;
  color: #000;
  z-index: 1;
  cursor: pointer;
  user-select: none;
}
.slide {
 /* transition-property: all ease;
  transition-duration: 2s; */
  overflow: hidden;
}
.slide-active {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 5px;
  margin: 10px 0px 10px 0px;
  border-radius: 20px;
  border: 1px #000000;
  box-shadow: 2px 2px 8px rgb(199, 199, 199);
}
/* @keyframes slide {
  0%{
    left: 200px;
  }
  100%{
    left:0;
  }
}
*/
.slider-image {
  max-width: 40%;
  max-height: 60%; 
  display: flex;
  flex-direction: column;
  align-items: left;
  
}
.slider-text {
  max-width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: right;
  font-family: Poppins;
  font-weight: 100;
  font-size: 18px;
  line-height: 35px;
  color: #662383;
  padding: 10px 20px;
  row-gap: 70px;
}

.slider-text p {
  max-width: 100%;
  text-align: left;
  align-items: right;
  font-family: Poppins;
  font-weight: 300;
  font-size: 19px;
  line-height: 25px;
  color: #161616;
  text-align: justify;
}
.contact-info p {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  font-weight: 400;
}
.contact-icon {
  margin: 10px 10px;
}

@media (max-width: 700px) {
  .slider-container {
    margin: 10px 10px 0;
    height: 800px;
    margin-bottom: -300px;
  }
  .slider-image {
    display: none;
  }
  .slider-container-header {
    font-size: 18px;
    line-height: 30px;
    padding-bottom: 40px;
  }
  .slider-container-header h1 {
    font-size: 20px;
  }
  .slider-container-header h4 {
    font-size: 15px;
    padding-top: 15px;
  }
  .slider-wrapper{
    height: 70%;
    margin-top: -30px;
    padding: 0px;
  }
  .slide-active {
    width: 100%;
    height: 70%;
    padding: 0;
    border: 1px;
    border-radius: 20px;
    border-color: #000;
    box-shadow: 2px 2px 10px rgb(199, 199, 199);
    overflow: scroll;
    margin: 0;
  }
  .slider-text {
    padding: 0px 0px;
    font-size: 16px;
    gap: 35px;
    max-width: 80%;
  }
  .slider-text p {
    font-size: 15px;
  }
  .slider-text h2 {
    font-size: 18px;
  }
  .left-arrow {
    top: 42%;
    left: 50px;
  }
  .right-arrow {
    top: 42%;
    right: 50px;
  }
  .container-dots {
    display: none;
  }
  .input {
    display: none;
  }
  .input.active {
    display: none;
  }

}
@media (max-width: 500px) {
  .contact-icon {
    height: 30px;
    width: 30px;
  }
  .left-arrow {
    top: 48%;
    left: 20px;
  }
  .right-arrow {
    top: 48%;
    right: 20px;
  }
}
.active-anim {
  opacity: 1;
}

.btn-slide {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #f1f1f1;
  border: 1px solid rgba(34, 34, 34, 0.287);
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-slide img {
  width: 25px;
  height: 25px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 20px;
  transform: translateY(-60%);
}
.next {
  top: 50%;
  right: 20px;
  transform: translateY(-60%);
}
.container-dots {
  display: flex;
	justify-content: space-around;
	align-items: center;
	width: 80vw;
	height: 100px;
  bottom: 100px;
	max-width: 1035px;
	position: relative;
}

/* 
	  position: absolute;
  width: 1035px;
  bottom: 100px;
  gap: 20%;
  left: 60%;
  transform: translateX(-50%);
  display: flex;
  */

.dots-text {
  position: absolute;
  width: 80%;
  bottom: 0px;
  gap: 20%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
}
.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #f1f1f1;
  margin: 0 5px;
  cursor: pointer;
  background: #f1f1f1;
}
.dot.active {
  background: #94C11F;
}
.text {
  display: flex;
  font-family: Poppins;
  font-weight: 300;
  font-size: 10px;
  line-height: 30px;
  color: #ecececf5;
  cursor: pointer;
}
.text h2{
  max-width: 550px;
}
.text.active {
  color: #94C11F;
}

.input {
  width: 25px;
  height: 25px;
  background-color: #662383;
  position: relative;
  border-radius: 50%; }
  .input:hover {
    cursor: pointer;
    width: 35px;
    height: 35px;
   }
  .input::before, .input::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 50%;
    transform: translateY(-50%);
    background-color: #662383;
    width: 125px;
    height: 5px;
    max-width: 200px; 
    transition-duration: 1s;}
  .input::before {
    left: calc(-130px + 12.5px); }
  .input::after {
    right: calc(-130px + 12.5px); }
  .input.active {
    background-color: #662383;
    transition-duration: 1s; }
    .input.active::before {
      background-color: #662383;
      transition-duration: 1s; }
    .input.active::after {
      background-color: #e8eff7;
      transition-duration: 1s; }
    .input.active span {
      font-weight: 700; }
      .input.active span::before {
        font-size: 13px; }
      .input.active span::after {
        font-size: 15px; }
  .input.active ~ .input, .input.active ~ .input::before, .input.active ~ .input::after {
    background-color: #e8eff7;
    transition-duration: 1s; }
  .input span {
    display: flex;
    transform: translate(-50%, -50%);
    height: 1px;
    font-size: 20px;
    position: inherit;
    top: 40px;
    width: 220px;
    justify-content: center;
    visibility: visible; }
    .input span:hover {
      
     }
    .input span::before, .input span::after {
      visibility: visible;
      position: absolute;
      left: 50%; }
    .input span::after {
      content: attr(slider-step);
      transform: translateX(-50%);
     }

@media (max-width: 700px) {
  .input span {
    padding: 0px 0px;
    font-size: 13px;
  }
}
